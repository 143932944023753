import React from "react"
import Banner from "../components/Banner/banner"
import TextBlock from "../components/TextBlock/textBlock"
import Button from "../components/Button/button"
import Packages from "../components/Packages/packages"
import Package from "../components/Package/package"
import Contact from "../components/Contact/contact"
import { Link } from "react-scroll"

import { IconContext } from "react-icons"
import { MdDone } from "react-icons/md"

const HomePage = () => {
  return (
    <>
      <Banner
        h1Txt={"On aeg muuta Sinu äri"}
        h1Span={"silmapaistvaks"}
        h2Txt={
          "Meie ettevõte on siin, et aidata Sinu äril saavutada endale seatud eesmärgid."
        }
        btnTxt={"Loe lisaks"}
        btnLink={"/#meist"}
      />
      <TextBlock
        id="meist"
        title="Uute IT lahenduste väljatöötamine pole kunagi olnud lihtne"
        paragraph="Meie meeskond ning koostööpartnerid on siin, et teha need muutused reaalsuseks."
      ></TextBlock>
      <Packages title="Teenused" para="">
        <IconContext.Provider
          value={{
            color: "#7FFF00",
            size: "1.2em",
            style: { verticalAlign: "middle", marginRight: "5px" },
          }}
        >
          <Package title="E-ÄRI LAHENDUSED">
            <ul>
              <li>
                <MdDone />
                E-poed
              </li>
              <li>
                <MdDone />
                Turundus ja SEO
              </li>
            </ul>
            <Link to="kontakt" smooth={true} duration={500}>
              <Button label="I want this" cta="Kirjuta meile!" />
            </Link>
          </Package>
          <Package title="Äri moderniseermine" active={true}>
            <ul>
              <li>
                <MdDone />
                IT-alased konsultatsioonid
              </li>
              <li>
                <MdDone />
                Veebiteenused
              </li>
            </ul>
            <Link to="kontakt" smooth={true} duration={500}>
              <Button label="I want this" cta="Kirjuta meile!" />
            </Link>
          </Package>
          <Package title="Andmete analüüs ja visualiseerimine">
            <ul>
              <li>
                <MdDone />
                Tableau, Looker
              </li>
              <li>
                <MdDone />
                Excel
              </li>
            </ul>
            <Link to="kontakt" smooth={true} duration={500}>
              <Button label="I want this" cta="Kirjuta meile!" />
            </Link>
          </Package>
        </IconContext.Provider>
      </Packages>
      <Contact id="kontakt" title="Leiame koos lahenduse Sinu äri kasvuks!" />
    </>
  )
}

export default HomePage
