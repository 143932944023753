import React from "react"
import styled from "styled-components"
import Button from "../Button/button"
import { navigate } from "gatsby"

const Contact = ({ title, subtitle, id }) => {
  const handleSubmit = event => {
    event.preventDefault()
    const name = event.target[0].value
    const from = event.target[1].value
    const clientMsg = event.target[2].value

    const raw = JSON.stringify({
      name,
      from,
      message: {
        subject: `Frontava.ee Lead (${name})`,
        text: `${clientMsg}`,
      },
    })

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    }

    fetch(`${process.env.GATSBY_API_URL}/hello`, requestOptions)
      .then(response => response.text())
      .then(result => {
        if (result.toLowerCase() === "success") {
          navigate(`/thanks`)
        }
      })
      .catch(error => {
        navigate(`/fail`)
      })
      .finally(() => {
        document.querySelector("#kontakt form").reset()
      })
  }

  return (
    <ContactWrapper id="kontakt">
      <div className="content-container">
        <h2>{title}</h2>
        <p>{subtitle}</p>
        <form name="kontakt" onSubmit={handleSubmit}>
          <div className="input-area">
            <input
              type="text"
              name="name"
              aria-label="Name"
              required
              autoComplete="off"
            />
            <label className="label-name" htmlFor="name">
              <span className="content-name">Nimi</span>
            </label>
          </div>

          <div className="input-area">
            <input
              type="email"
              name="email"
              aria-label="Email"
              required
              autoComplete="off"
            />
            <label className="label-name" htmlFor="email">
              <span className="content-name">Email</span>
            </label>
          </div>

          <div className="input-area">
            <textarea
              type="text"
              name="message"
              rows="5"
              required
              autoComplete="off"
              aria-label="Message"
            />
            <label className="label-name" htmlFor="message">
              <span className="content-name">Sõnum</span>
            </label>
          </div>

          <div className="input-area button-area">
            <Button label="Saada sõnum" cta="Saada sõnum" type="submit" />
          </div>
        </form>
      </div>
    </ContactWrapper>
  )
}

const ContactWrapper = styled.section`
  padding: 100px 30px;

  .content-container {
    width: 100%;
    margin: 0 auto;

    h2 {
      text-align: left;
      background: -webkit-linear-gradient(45deg, #f441a5, #03a9f4);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media (min-width: 768px) {
        text-align: center;
      }
    }
    p {
      margin-bottom: 2rem;

      @media (min-width: 768px) {
        text-align: center;
      }
    }

    form {
      position: relative;
      overflow: hidden;

      .input-area {
        margin-bottom: 40px;
        position: relative;

        &.button-area {
          text-align: center;
          margin-bottom: 0;
        }
      }

      input,
      textarea {
        height: 100%;
        font-size: 1rem;
        letter-spacing: 0.25rem;
        padding: 20px;
        display: block;
        width: 100% !important;
        border: none;
        background-color: #0b132e;
        color: #fff;
        // text-transform: uppercase;
        position: relative;
        box-sizing: border-box;
        outline: none;

        &:focus,
        &:valid {
          + .label-name {
            .content-name {
              transform: translateY(-25%);
              font-size: 0.7rem;
              opacity: 0.2;
            }
            &::after {
              transform: translateX(0%);
            }
          }
        }
      }

      label {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        pointer-events: none;

        &::after {
          content: "";
          position: absolute;
          left: 0px;
          bottom: -1px;
          height: 1px;
          background: linear-gradient(90deg, #f441a5, #03a9f4);
          width: 100%;
          transform: translateX(-100%);
          transition: transform 0.3s ease;
        }
      }

      .content-name {
        position: absolute;
        top: 10px;
        left: 20px;
        transition: all 0.3s ease;
        text-transform: uppercase;
        letter-spacing: 0.25rem;
        font-size: 0.8rem;
      }
    }
  }
`

export default Contact
